<template>
  <div>
    <div class="s-steps">
      <div
        v-for="i in steps"
        :key="i.order"
        class="s-steps__step"
        :class="i.status"
      >
        <div class="s-steps__circle">
          <img v-if="i.icon_url" :src="i.icon_url" class="img-fluid" />
          <template v-else>
            {{ i.order }}
          </template>
        </div>
        <div class="s-steps__title">{{ i.title }}</div>
      </div>
      <div class="s-steps__bar">
        <span
          v-for="i in steps.length - 1"
          :key="i"
          class="s-steps__indicator"
          :class="steps[i].status"
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    steps: {
      type: Array,
    },
  },
  data() {
    return {
      // steps: [
      //   {
      //     order: 1,
      //     title: "填寫資料",
      //     status: "active",
      //   },
      //   {
      //     order: 2,
      //     title: "手機驗證",
      //     status: "unactive",
      //   },
      //   {
      //     order: 3,
      //     title: "註冊完成",
      //     status: "unactive",
      //   }
      // ],
    };
  },
};
</script>

<style lang="scss">
.s-steps {
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  z-index: 0;

  &__step {
    text-align: center;
    background: var(--liff-content_bg_color);
  }

  &__circle {
    aspect-ratio: 1;
    margin: auto;
    width: 50px;
    border-radius: 50%;
    border: 3px solid #a5a5a5;
    // outline: 4px solid #DDD;
    // outline-offset: 3px;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    line-height: 0;
    color: #a5a5a5;
    //box-shadow: 0px 0px 0px 10px var(--liff-content_bg_color);
    overflow: hidden;
  }

  &__bar {
    position: absolute;
    height: 0;
    width: 100%;
    z-index: -1;
    display: flex;
    transform: translateY(-11px);
  }

  &__indicator {
    height: 0;
    border-top: dotted 3px #a5a5a5;
    flex: 1 1 auto;

    &.active {
      border-color: var(--s-primary);
    }
    &.done {
      border-color: var(--s-primary);
      border-style: solid;
    }
  }

  &__title {
    color: #a5a5a5;
    font-size: var(--s-text-sm);
    font-weight: bold;
  }
}

.s-steps__step.active {
  .s-steps__circle {
    background: var(--s-primary);
    color: var(--liff-content_bg_color);
    box-shadow: inset 0px 0px 0px 3px var(--liff-content_bg_color);
    border-color: var(--s-primary);
  }
  .s-steps__title {
    color: var(--s-primary);
  }
}

.s-steps__step.done {
  .s-steps__circle {
    background: var(--s-primary);
    color: var(--liff-content_bg_color);
    border-color: var(--s-primary);
  }
  .s-steps__title {
    color: var(--s-primary);
  }
}
</style>
