import https from "./https";
import store from "@/store";

const register = {
  register(params) {
    const organization = store.state.liffGeneral.orgCode;

    return https.post(`${organization}/liff/register/register`, params);
  },

  onlyVerify(params) {
    const organization = store.state.liffGeneral.orgCode;

    return https.post(`${organization}/liff/register/only-verify`, params);
  },

  verifyPhone(code) {
    const organization = store.state.liffGeneral.orgCode;
    return https.post(`${organization}/liff/general/phone/verify`, { code });
  },

  verifyByMobile(code, mobile_phone) {
    const organization = store.state.liffGeneral.orgCode;
    return https.post(`${organization}/liff/general/phone/verify`, { code, mobile_phone });
  },

  sendPhone(mobile_phone) {
    const organization = store.state.liffGeneral.orgCode;
    return https.post(`${organization}/liff/general/phone/send`, { mobile_phone });
  },

  resendPhone() {
    const organization = store.state.liffGeneral.orgCode;
    return https.post(`${organization}/liff/general/phone/resend`);
  },

  checkRegistered(params) {
    const organization = store.state.liffGeneral.orgCode;
    return https.get(`${organization}/liff/register/check-registered`, {
      params
    });
  },

  overwriteBinding(params) {
    const organization = store.state.liffGeneral.orgCode;
    return https.post(`${organization}/liff/register/overwrite-binding`, params);
  },

  getBranches(params) {
    const organization = store.state.liffGeneral.orgCode;
    return https.get(`${organization}/liff/branches`, {
      params
    });
  },

  getSingleBranch(branchId) {
    const organization = store.state.liffGeneral.orgCode;
    return https.get(`${organization}/liff/branches/${branchId}`);
  },

  unbind() {
    const organization = store.state.liffGeneral.orgCode;
    return https.post(`${organization}/liff/register/reset`);
  }
};

export default register;
