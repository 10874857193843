<template>
  <div
    v-bind:class="{
      'react-code-input-container': true,
      [className]: !!className
    }"
    v-bind:style="{ width: `${fieldWidth}px` }"
  >
    <p class="title" v-if="title">{{title}}</p>
    <div class="react-code-input">
      <input
        :type="type === 'number' ? 'tel' : type"
        :pattern="type === 'number' ? '[0-9]' : null"
        :autoFocus="autoFocus && !loading"
        :style="{
          width: `${fieldWidth}px`,
          height: `${fieldHeight}px`
        }"
        :value="value"
        v-on:input="onInput"
        :disabled="disabled"
        :required="required"
        :maxlength="fields"
      />
    </div>
    <div v-if="loading" class="loading" :style="{lineHeight: `${fieldHeight}px`}">
      <div class="blur" />
      <svg
        class="spin"
        viewBox="0 0 1024 1024"
        data-icon="loading"
        width="1em"
        height="1em"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fill="#006fff"
          d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "CodeInput",
  props: {
    type: {
      type: String,
      default: "number"
    },
    className: String,
    fields: {
      type: Number,
      default: 6
    },
    fieldWidth: {
      type: Number,
      default: 348
    },
    fieldHeight: {
      type: Number,
      default: 54
    },
    autoFocus: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    title: String,
    change: Function,
    complete: Function,
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      value: '',
    }
  },
  methods: {
    onInput(e) {
      let value = e.target.value;

      if (this.type === "number") {
        value = value.replace(/[^\d]/gi, "");
      }

      this.value = value;
      e.target.value = value;

      this.triggerChange(value);
    },
    triggerChange(value = this.value) {
      const { fields } = this;
      this.$emit("change", value);
      if (value.length >= fields) {
        this.$emit("complete", value);
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.react-code-input-container {
  position: relative;
}

.react-code-input > input {
  border: solid 1px #a8adb7;
  border-right: none;
  font-family: "Lato";
  font-size: 20px;
  color: #525461;
  text-align: center;
  box-sizing: border-box;
  border-radius: 0;
  -webkit-appearance: initial;
}

.react-code-input > input:last-child {
  border-right: solid 1px #a8adb7;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.react-code-input > input:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.react-code-input > input:focus {
  outline: none;
  border: 1px solid #006fff;
  caret-color: #006fff;
}

.react-code-input > input:focus + input {
  border-left: none;
}

.loading {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
}

.blur {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  opacity: 0.5;
  filter: blur(0.5px);
  transition: opacity 0.3s;
}

.title {
  margin: 0;
  height: 20px;
  padding-bottom: 10px;
}

.spin {
  display: inline-block;
  animation: loadingCircle 1s infinite linear;
}

@keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}
</style>
