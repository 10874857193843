<template>
  <div>
    <div v-if="displayLoading">
      <Loading />
    </div>

    <div class="page">


      <div v-if="getModuleConfig('validate_page.step_section.enable_step_section')" class="p-3">
          <SharedStep :steps="getModuleConfig('validate_page.step_section.steps')" />
        </div>


      <div class="page__title">
        {{ getModuleConfig("validate_page.form_title") }}
      </div>
      <div class="page__desc">
        {{ getModuleConfig("validate_page.form_desc") }}
      </div>



      <div class="page__desc__text">
        簡訊驗證碼已發送至<b>{{ mobile }}</b
        >，<br />
        <div v-if="counter">
            驗證碼將於 <b>{{ timeout.minute }}</b> 分
            <b>{{ timeout.second }}</b> 秒後失效
        </div>
        <div v-else>
            驗證碼已失效，請重新驗證。
        </div>
      </div>

      <div class="code-input">
        <CodeInput
          :loading="false"
          :fields="6"
          :fieldHeight="80"
          :class="{ invalid: !isValid }"
          @change="onChange"
          :disabled="!counter"
        />
        <div v-if="!isValid" class="form__error-msg">
          驗證碼有誤，請再次確認
        </div>
      </div>

      <div class="page__bottom">
        沒收到嗎？<span @click="handleResend">重新發送驗證碼</span>
      </div>
      <div class="page__bottom">
        手機號碼錯誤？<span @click="handleReturn">重新填寫資料</span>
      </div>
    </div>
    <div class="page__button">
      <SharedButton
        class="s-btn-bg-primary"
        type="button"
        @click="handleSubmit"
      >
        送出
      </SharedButton>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Page/Liff/Shared/Loading";
import CodeInput from "@/components/CodeInput";
import reigsterApi from "@/apis/liff/v2/register";
import SharedButton from "@/components/Page/Liff/Shared/Button";
import registerMixin from "@/mixins/liff/register";
import SharedStep from "@/components/Page/Liff/Shared/Steps";

export default {
  mixins: [registerMixin],
  components: {
    CodeInput,
    Loading,
    SharedButton,
    SharedStep
  },

  data() {
    return {
      displayLoading: false,
      verificationCode: null,
      counter: 120,
      ttl: 120,
      timer: null,
      isValid: true,
    };
  },

  computed: {
    timeout() {
      return {
        minute: Math.floor(this.counter / 60),
        second: Math.floor(this.counter % 60),
      };
    },
    mobile() {
      const mobile = this.$store.state.liffAuth.user.mobile;
      return mobile
        ? mobile.substring(0, 3) + "****" + mobile.substring(7, 10)
        : null;
    },
    timeString() {
      let timeString = "";
      if (this.timeout.minute) timeString += `${this.timeout.minute}分 `;
      if (this.timeout.second) timeString += `${this.timeout.second}秒`;
      return timeString;
    },
  },

  beforeDestroy() {
    this.clearTimer();
  },

  mounted() {
    this.ttl = this.getModuleConfig("verify_mobile.retry_gap_seconds");
    this.counter = this.ttl;
    this.startTimer();
  },

  methods: {
    onChange(e) {
      this.verificationCode = e;
    },

    async handleResend() {
      this.displayLoading = true;
      this.isValid = true;

      try {
        await reigsterApi.resendPhone();
        this.counter = this.ttl;
        this.restartTimer();
        this.$swal("成功", `簡訊驗證碼已送出`, "Success");
      } catch (error) {
        if (
          error.response.data.status === "failed" &&
          error.response.data.expired_at
        ) {
          this.counter =
            error.response.data?.expired_at -
            Math.floor(Date.now() / 1000) +
            1;
          this.$swal("請稍候", `請於 ${this.timeString} 後，再次點選「重新發送驗證碼」`, "error");
        }
        this.restartTimer();
      }

      this.displayLoading = false;
    },

    async handleSubmit() {
      this.displayLoading = true;

      try {
        let response = await reigsterApi.verifyPhone(this.verificationCode);
        if (response.data.status == 'success' && response.data.next) {
          this.goNext(response.data.next)
          return;
        }

        await this.fetchMe();
        this.$router.push({
          name: "LiffRegisterWelcome",
          params: {
            orgCode: this.$route.params.orgCode,
          },
        });

      } catch (error) {
        console.log("error", error);
        this.isValid = false;
      }

      this.displayLoading = false;
    },

    startTimer() {
      this.timer = window.setInterval(() => {
        this.counter--;

        if (this.counter > 0) {
          return;
        }

        this.clearTimer();
      }, 1000);
    },
    clearTimer() {
      if (!this.timer) {
        return;
      }

      window.clearInterval(this.timer);
      this.timer = null;
    },
    restartTimer() {
      this.clearTimer();
      this.startTimer();
    },
    handleReturn() {
      this.$router.push({
        name: "LiffRegisterMember",
        params: {
          orgCode: this.$route.params.orgCode,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form__error-msg {
  color: #fe0000;
  font-size: 12px;
  margin-top: 8px;
  margin-left: calc((100% - 60px * 5 - 8px * 4) / 2);
}
.code-input {
  margin-bottom: 32px;
  margin-top: 20px;

  ::v-deep .react-code-input-container {
    margin: auto;
    text-align: center;

    &.invalid input {
      border: 1px solid #fe0000;
    }
  }
  ::v-deep input {
    border: 1px solid #636366 !important;
    border-radius: 10px !important;
    font-size: 50px;
    font-weight: 600;
    font-family: PingFang TC;
    color: var(--primary-color) !important;

    &:not(:last-of-type) {
      margin-right: 8px;
    }

    &:focus {
      border: 1px solid var(--liff-primary_text_color);
      caret-color: var(--liff-primary_text_color);
    }
    &:focus + input {
      border: 1px solid #636366;
    }
  }
}
.page__bottom {
  font-size: 14px;
  line-height: 22px;
  color: var(--liff-primary_text_color);
  text-align: center;
  span {
    text-decoration: underline;
    cursor: pointer;
    color: var(--primary-color) !important;
  }
}
.page__desc__text {
  text-align: center;
  color: var(--liff-primary_text_color) !important;
  b {
    color: var(--primary-color) !important;
  }
}
.page__desc {
  color: var(--liff-secondary_text_color);
}
</style>
